import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

const content_spacing = '25px';
const md_content_spacing = '35px';

const md_text_offset = '20px'
const lg_text_offset = '35px'

const imageOffset = ({direction = 'left'}) => css`

    ${theme.mediaQuerys.smUp} {
        padding-${direction}: 20px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-${direction}: 35px;
    }
`

const alignText = css`
    text-align: ${props => props.$textAlign || ''};
`

export const StyledContentContainer = styled(StyledContainer)`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: ${props => props.$imageAlign && 
                props.$imageAlign !== 'image-right' ? 
                    'row' : 'row-reverse'};

    ${theme.mediaQuerys.smUp} {
        ${alignText}
    }

    .image-col-controller {
        width: 75%;

        ${theme.mediaQuerys.smUp} {
            width: 40%;
        }  

        ${theme.mediaQuerys.mdUp} {
            width: 37.5%;
        }  
    }

    .text-col-controller {

        ${theme.mediaQuerys.smUp} {
            flex: 1;
            padding: 0 20px;
        }

        ${theme.mediaQuerys.mdUp} {
            padding: 0 50px;
        }

        ${theme.mediaQuerys.lgUp} {
            padding: 0 75px;
        }
    }

    .title-and-image-container {
        

        ${theme.mediaQuerys.smUp} {
            margin: 0 auto ${content_spacing} auto;
        }

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: ${md_content_spacing};
        }
    }

    .button-align-controller {
        ${props => props.$imageAlign === 'image-right' ? css`
            margin-left: auto;
        `:css` 
            margin-right: auto;
        `}
    }

    ${props => props.$enableTitleRowOnMobile && css`
       

        .title-and-image-container {
            ${alignText};
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 450px;
            flex-direction: ${
                props.$imageAlign !== 'image-right' ? 
                    'row-reverse' : 'row'};
            
            ${theme.mediaQuerys.smUp} {
                max-width: unset;
            }
        }

        .title-col-controller {
            width: 50%;

            ${theme.mediaQuerys.smUp} {
                width: 100%;
            }   
        }

        .image-col-controller {
            width: calc(50% - 15px);

            ${theme.mediaQuerys.smUp} {
                width: 40%;
            }   

            ${theme.mediaQuerys.mdUp} {
                width: 37.5%;
            }   
        }
    `}

    .image-offset {

        ${props => props.$imageAlign === 'image-right' ? 
            imageOffset({ direction: 'right'}) 
        : 
            imageOffset({ direction: 'left'}) 
        }
    }

    .image-flag-offset {
        ${props => props.$imageAlign === 'image-right' ? css`
            right: -20px;
        `:css`
            left: -20px
        `}
    }
`

export const StyledFeaturedImageContainer = styled.div`
    position: relative;
    margin: 0 auto ${content_spacing} auto;

    ${theme.mediaQuerys.smUp} {
        margin: 0;
    }
    
    display: ${props => props.$isDesktopImage ? 'none' : ''};
    
    ${theme.mediaQuerys.smUp} {
        display: ${props => !props.$isDesktopImage ? 'none' : 'block'};
    }
`

export const StyledImageFlag = styled.div`
    background-color: ${props => props.$backgroundColor || theme.colors.white};
    color: ${props => props.$textColor || theme.colors.black};
    font-size: 1.4rem;
    line-height: 18px;
    text-align: center;
    padding: 13px 15px 15px 15px;
    border-radius: 50px;
    min-height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    
    ${theme.mediaQuerys.smUp} {
        position: absolute;
        bottom: 30px;
        max-width: calc(100% - 20px);

        ${props => props.$isMobileOnly && css`
            display: none;
        `}
    }

    ${theme.mediaQuerys.mdUp} {
        bottom: 40px;
        font-size: 1.6rem;
        line-height: 21px;   
        padding: 13px 20px 15px 20px;    
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    margin: 0 auto;
`

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0 auto ${content_spacing} auto;

    ${theme.mediaQuerys.smUp} {
        margin: 0;
    }
`

export const StyledSubText = styled(WysiwygContent)`
    margin-bottom: ${content_spacing};
    /* font-size: 1.8rem;
    line-height: 24px; */
    padding: 0 10px;
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        margin-top: 30px;
        padding: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-top: 0;
        /* font-size: 2.2rem;
        line-height: 29px; */
        /* margin-bottom: ${md_content_spacing}; */
    }

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }

    p {
        /* margin: 0 0 ${content_spacing} 0; */

        /* ${theme.mediaQuerys.mdUp} {
            margin-bottom: ${md_content_spacing};
        } */
    }
`

export const StyledButtonAndPointerContainer = styled.div`
    position: relative;
    width: 100%;

    ${props => props.$isMobileOnly && css`
        ${theme.mediaQuerys.smUp} {
            display: none;
        }
    `}    
`

export const StyledButton = styled(WrButton)`
    
    ${theme.mediaQuerys.smUp} {
        max-width: 450px;
    }

    ${props => props.$isImageRight && css`
        margin-left: auto;
    `}
`

export const StyledPointer = styled(WrPointer)`
    position: absolute;
    bottom: -100px;
    right: -60px;
    height: 100px;
    transform: rotate(185deg) scaleX(-1);

    ${theme.mediaQuerys.mdUp} {
        right: -65px;
        bottom: -110px;
        height: 115px;
    }

    ${theme.mediaQuerys.lgUp} {
        right: -85px;
        height: 135px;
        transform: rotate(175deg) scaleX(-1);
    }
`