import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '../../../commons/wysiwyg-content';

const content_spacing = '25px';
const md_content_spacing = '35px';

const md_text_offset = '20px'
const lg_text_offset = '35px'

export const StyledGridContentContainer = styled(StyledContainer)`
 
    ${theme.mediaQuerys.smUp} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto 1fr;
        grid-column-gap: 20px;
        align-items: flex-start;
    }

    ${theme.mediaQuerys.mdUp} {
        grid-template-columns: .8fr 1.2fr;
        grid-column-gap: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        grid-template-columns: .85fr 1.15fr;
        grid-column-gap: 60px;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    grid-area: featured-image;
    grid-column: 1;
    grid-row-start: 2;
    width: 100%;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        margin: auto;
    }

    ${theme.mediaQuerys.mdUp} {
        position: relative;
        top: -5px;
        grid-row-start: 1;  
    }

    ${theme.mediaQuerys.lgUp} {
        top: -15px;
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0;
    margin-bottom: ${content_spacing};
    text-align: center;


    ${theme.mediaQuerys.smUp} {
        text-align: left;
        width: auto;
        min-height: auto;
        grid-area: title;
        grid-column: 1 / 3;
        grid-row: 1;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
        padding-left: ${md_text_offset};
        padding-right: ${md_text_offset};
        grid-column: 2;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-left: ${lg_text_offset};
        padding-right: ${lg_text_offset};
    }
`

export const StyledSubText = styled(WysiwygContent)`
    grid-area: subtext;
    grid-column: 2;
    grid-row: 2;
    margin-bottom: ${content_spacing};
    text-align: center;
    padding: 0 10px;

    ${theme.mediaQuerys.smUp} {
        margin-top: 30px;
        text-align: left;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-top: 0;
        margin-bottom: ${md_content_spacing};
        padding: 0 ${md_text_offset} 0 ${md_text_offset};
    }

    ${theme.mediaQuerys.lgUp} {
        padding: 0 ${lg_text_offset};
    }

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }

    p {
        margin: 0 0 ${content_spacing} 0;
    }
`

export const StyledButtonAndPointerContainer = styled.div`
    position: relative;
    grid-area: button;
    grid-column: 2;
    grid-row: 3;
    padding: 0 5px;

    ${theme.mediaQuerys.mdUp} {
        padding-right: ${md_text_offset};
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
    }
    
`

export const StyledButton = styled(WrButton)`

`

export const StyledPointer = styled(WrPointer)`
    position: absolute;
    bottom: -100px;
    right: -60px;
    height: 100px;
    transform: rotate(185deg) scaleX(-1);

    ${theme.mediaQuerys.mdUp} {
        right: -65px;
        bottom: -110px;
        height: 115px;
    }

    ${theme.mediaQuerys.lgUp} {
        right: -85px;
        height: 135px;
        transform: rotate(175deg) scaleX(-1);
    }
`