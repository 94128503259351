import React from 'react'
import { StyledFeaturedImageContainer, StyledImageFlag, StyledButtonAndPointerContainer, StyledButton, StyledContentContainer, StyledFeaturedImage, StyledImageWithTextContent, StyledSubText, StyledTitle } from './styles'

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function ImageWithTextContent({
    module,
}) {
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            innerSpacing={module.enablePointer ? true : ''}
            data-name="testimonials_image-with-text-content"
        >
          
            <StyledContentContainer
                type={'outer'}
                $textAlign={module.imageDisplay === 'image-right' ? 'right' : 'left'}
                $imageAlign={module.imageDisplay}
                $enableTitleRowOnMobile={!module.buttonLink && module.text}
            >    
                <Image 
                    module={module} 
                    isDesktopImage={true}
                />
                <div className="text-col-controller">
                    <div className="title-and-image-container">
                        {module.title && 
                            <StyledTitle
                                titleFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                                className="title-col-controller"
                            >
                                {module.title}
                            </StyledTitle>
                        }   
                         <Image 
                            module={module} 
                            isDesktopImage={false}
                            showFlag={false}
                        />
                    </div>                 
            
                    {module.text && 
                        <StyledSubText 
                            bodyFontOptions={{ mobile: 'sm', desktop: 'lg' }}
                            // dangerouslySetInnerHTML={{ __html: module.text }}
                            $isImageRight={module.imageDisplay === 'image-right'}
                        >
                            {module.text}
                        </StyledSubText>
                    }
                    
                    <StyledButtonAndPointerContainer
                    >
                        {module.buttonLinkOne ?
                            <StyledButton 
                                className="button-align-controller"
                                href={module.buttonLinkOne.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                            >
                                {module.buttonLinkOne.title}
                            </StyledButton>
                        : module.imageFlag ?
                            <StyledImageFlag 
                                dangerouslySetInnerHTML={{ __html: module.imageFlag}}
                                $backgroundColor={module.flagBackgroundColor}
                                $textColor={module.flagTextColor}
                                $isMobileOnly={true}
                            />
                        : null}
                        
                    </StyledButtonAndPointerContainer>   
                </div>      
            </StyledContentContainer>
        </StyledModuleContainer>
    )
    return null;
}

const Image = ({ module, isDesktopImage, showFlag = true }) => (
    <StyledFeaturedImageContainer 
        $isDesktopImage={isDesktopImage}
        className="image-offset image-col-controller"
    >
        {module.featuredImage && 
            <StyledFeaturedImage       
                alt={module.featuredImage.altText}
                imageFile={module.featuredImage.imageFile} 
            />
        }
        
        {module.imageFlag && showFlag ? 
            <StyledImageFlag 
                className="image-flag-offset"
                dangerouslySetInnerHTML={{ __html: module.imageFlag}}
                $backgroundColor={module.flagBackgroundColor}
                $textColor={module.flagTextColor}
            />
        : null}  
    </StyledFeaturedImageContainer>
)