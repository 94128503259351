import React from 'react'
import { StyledButtonAndPointerContainer, StyledPointer, StyledButton, StyledGridContentContainer, StyledFeaturedImage, StyledImageWithTextContent, StyledSubText, StyledTitle } from './styles'

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function ImageWithTextContent({
    module,
}) {
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            innerSpacing={module.enablePointer ? true : ''}
            isOverflowHidden={module.enablePointer ? true : ''}
            data-name="image-with-text-content"
        >
            <StyledGridContentContainer
                type={'outer'}
            >       
                {module.title && 
                    <StyledTitle
                        titleFontOptions={{ mobile: 'md', desktop: 'sm' }}
                    > 
                        {module.title}
                    </StyledTitle>
                }

                {module.featuredImage && 
                    <StyledFeaturedImage 
                        alt={module.featuredImage.altText}
                        imageFile={module.featuredImage.imageFile}
                    />
                }
                
                {module.text && 
                    <StyledSubText 
                        bodyFontOptions={{ mobile: 'md', desktop: 'sm' }}
                    >
                        {module.text}
                    </StyledSubText>
                }

                {module.buttonLink && 
                    <StyledButtonAndPointerContainer>
                        <StyledButton 
                            href={module.buttonLink.url} 
                            buttonOptions={module.buttonOptions}
                            fontSize="sm"
                        >
                            {module.buttonLink.title}
                        </StyledButton>

                        {module.enablePointer && 
                            <StyledPointer 
                                color={module.pointerColor} 
                            />
                        }
                    </StyledButtonAndPointerContainer>
                }            
            </StyledGridContentContainer>
        </StyledModuleContainer>
    )
    return null;
}
